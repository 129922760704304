/// Builds a selector for a motion class, using the settings defined in the `$motion-ui-classes` and `$motion-ui-states` maps.
/// @param {String|List} $states - One or more strings that correlate to a state.
/// @param {Boolean} $active - Defines if the selector is for the setup or active class.
/// @return {String} A selector that can be interpolated into your Sass code.
/// @access private
@function -mui-build-selector($states, $active: false) {
  $return: '';
  $chain: map-get($motion-ui-classes, chain);
  $prefix: map-get($motion-ui-classes, prefix);
  $suffix: map-get($motion-ui-classes, active);

  @each $sel in $states {
    $return: $return + if($chain, '&.', '#{&}-') + $prefix + $sel;

    @if $active {
      $return: $return + if($chain, '.', '#{&}-') + $prefix + $sel + $suffix;
    }

    $return: $return + ', ';
  }

  @return str-slice($return, 1, -3);
}
