/// Creates a shaking animation.
/// @param {Percentage} $intensity [7%] - Intensity of the shake, as a percentage value.
/// @return {Map} A keyframes map that can be used with the `generate-keyframes()` mixin.
@function shake($intensity: 7%) {
  $right: (0, 10, 20, 30, 40, 50, 60, 70, 80, 90);
  $left: (5, 15, 25, 35, 45, 55, 65, 75, 85, 95);

  $keyframes: (
    name: -mui-string-safe('shake-#{$intensity}'),
    $right: (transform: translateX($intensity)),
    $left: (transform: translateX(-$intensity)),
  );

  @return $keyframes;
}
