/// Creates a fading animation.
/// @param {Number} $from [0] - Opacity to start at.
/// @param {Number} $to [1] - Opacity to end at.
/// @return {Map} A keyframes map that can be used with the `generate-keyframes()` mixin.
@function fade(
  $from: 0,
  $to: 1
) {
  $type: type-of($from);
  $keyframes: ();

  @if $type == 'string' {
    @if $from == in {
      $from: 0;
      $to: 1;
    } @else if $from == out {
      $from: 1;
      $to: 0;
    }
  }

  $keyframes: (
    name: -mui-string-safe('fade-#{$from}-to-#{$to}'),
    0: (opacity: $from),
    100: (opacity: $to),
  );

  @return $keyframes;
}
