// Utility Color Classes

//.success {
//
//}

// Google Color Palette defined: http://www.google.com/design/spec/style/color.html

$grey: #666;
$white: #fff;
$black: #333;

$ungu: hsla(308, 22%, 80%, 1);
$kuning: hsla(52, 62%, 86%, 1);
$ijo: hsla(169, 52%, 90%, 1);
$jingga: hsla(24, 67%, 86%, 1);
$blue-1 	: hsla(198, 100%, 44%, 1);
$green-1	: hsla(176, 49%, 48%, 1);
$green-2	: hsla(160, 39%, 64%, 1);
$yellow-1	: hsla(58, 41%, 75%, 1);
$pink-1		: hsla(1, 100%, 81%, 1);
$pink-2		: hsla(340, 100%, 62%, 1);
$gold		: hsla(49, 100%, 59%, 1);

$dark-gold : darken($gold, 30%);
