/// Processes a series of keyframe function arguments.
/// @access private
@function -mui-process-args($args...) {
  @if length($args) == 1 {
    $arg: nth($args, 1);

    @if -mui-is-function($arg) {
      @return -mui-safe-call($arg);
    } @else if type-of($arg) == 'map' {
      @return $arg;
    }
  }

  @return -mui-keyframe-combine($args...);
}
