/// Creates a fade transition by adjusting the opacity of the element.
/// @param {Keyword} $state [in] - State to transition to.
/// @param {Number} $from [null] - Opacity to start at. Must be a number between 0 and 1. By default `0` and `1` for `in` and `out` states respectively.
/// @param {Number} $to [null] - Opacity to end on. By default `1` and `0` for `in` and `out` states respectively.
/// @param {Keyword} $duration [null] - Length (speed) of the transition.
/// @param {Keyword|Function} $timing [null] - Easing of the transition.
/// @param {Duration} $delay [null] - Delay in seconds or milliseconds before the transition starts.
@mixin mui-fade(
  $state: in,
  $from: null,
  $to: null,
  $duration: null,
  $timing: null,
  $delay: null
) {
  $from: if($from != null, $from, if($state == in, 0, 1));
  $to: if($to != null, $to, if($state == in, 1, 0));
  $fade: fade($from, $to);

  @include transition-start($state) {
    @include transition-basics($duration, $timing, $delay);
    @include -mui-keyframe-get($fade, 0);

    transition-property: opacity;
  }

  @include transition-end($state) {
    @include -mui-keyframe-get($fade, 100);
  }
}
