/// Creates a spinning animation.
/// @param {Keyword} $direction [null] - Direction to spin. Should be `cw` (clockwise) or `ccw` (counterclockwise). By default `cw` and `ccw` for `in` and `out` states respectively.
/// @param {Number} $amount [360deg] - Amount to spin. Can be any CSS angle unit.
/// @return {Map} A keyframes map that can be used with the `generate-keyframes()` mixin.
@function spin(
  $state: in,
  $direction: null,
  $amount: 1turn
) {
  $start: 0;
  $end: 0;
  $direction: if($direction != null, $direction, if($state == in, cw, ccw));

  @if $state == in {
    $start: if($direction == ccw, $amount, $amount * -1);
    $end: 0;
  } @else {
    $start: 0;
    $end: if($direction == ccw, $amount * -1, $amount);
  }

  $keyframes: (
    name: -mui-string-safe('spin-#{$direction}-#{$amount}'),
    0: (transform: rotate($start)),
    100: (transform: rotate($end)),
  );

  @return $keyframes;
}
