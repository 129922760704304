.appContainer {
  background: url('../img/damaskbg2b.jpg') repeat repeat;
  overflow: hidden;
}

ul {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}

.slick-slide img {
  margin: auto;
  width: 100%;
}

*:focus {outline:none !important}

.page {
  background-color: transparentize($primary-color, 0.7);
  padding: 1rem;
  box-shadow: 0 6px 10px -5px #999;
}

.page-title {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.8rem;
  text-transform: capitalize;
  @include tablet {
    font-size: 1.4rem;
  }
  @include phone {
    font-size: 1.2rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.main-content {
  // background-color: #FFF; 
}

a, .button, button {
  cursor: pointer;
  transition: all 0.2s linear;
}

a, button:hover, button:focus, .button:hover, .button:focus{outline-style: none;}


.button, button {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0;
}

header {
  a, button {
    color: #999;
  }
  border-bottom: solid 15px lighten($primary-color, 10%);
  @include phone {
    border-width: 5px;
  }

  .logo {
    text-align: center;
    @include phone {
      text-align: left;
      a {
        display: block;
        padding: 0.5rem;
      }
    }
    img {
      padding: 2rem 2rem 0;
      margin: auto;
      @include phone {
        padding: 0;
        max-height: 45px;
        width: auto;
      }
    }
  }
}

.main-navi {
  button {
    i {
      font-size: 1.4rem;
      padding: 1rem;
    }
  }
  .main-links {
    > div a {
      padding: 0.5rem 1rem;
      display: block;
      font-size: 1.4rem;
      color: #777;
    }
  }
  padding-bottom: 1rem;
  @include phone {
    padding-bottom: 0;
  }
  .menu-toggle {
    @include phone {

    }
  }
}

.reveal {
  padding-top: 4rem;
}

.search-bar {
  position: relative;
  i {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.4rem;
    color: #999;
  }
}

h1, h2, h3, h4, h5, h6 {

}


// ****************************************************************************
// *                       screen and preloader settings                      *
// ****************************************************************************


.screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0, 0, 0.8);
  // background-color: transparentize(#fff, 0.2);
  
  z-index: 998;
  display: none;
}

.menu-screen {
  // background-color: transparentize(#fff, 0.2);
}

.mobile-menu-wrapper, .action-menu-wrapper, .user-menu-wrapper {
  // padding-top: 1rem;
  display: none;
  z-index: 999;
  background-color: #fff;
  min-height: 100vh;
  width: 320px;
  position: absolute;
  top: 0;
  text-transform: capitalize;
  box-shadow: 0 2px 20px #000;
  .close-button {
    color: #999;
  }
}


.action-menu-wrapper, .user-menu-wrapper {
  right: 0;
}

.mobile-menu, .action-menu, .user-menu {
  overflow-y: auto;
  .menu-top {
    padding: 1rem;
    a {
      border: none;
    }
  }
  .list-header {
    font-size: 1.5rem;
    padding: 0.6rem 1rem;
    border-bottom: solid 1px $primary-color;
    border-top: solid 3px $primary-color;
    a {
      border: none;
      font-size: 1.5rem;
      padding-left: 0;
    }
  }
  a {
    color: $primary-color;
    font-size: 1.2rem;
    // font-family: $header-font-family;
    padding: 0.6rem 1rem;
    display: block;
    border-bottom: solid 1px $primary-color;
  }
}

body {
  &.menu-open {
    // overflow: hidden;
    .appContainer {
    }
    .screen {
      // background-color: transparentize(#fff, 0.2);
      overflow: hidden;
    }
  }
  &.user-menu-open {
    overflow: hidden;
  }
}

nav {
  font-family: $header-font-family;
}

header {
  // box-shadow: 0 3px 10px #ccc;
}


.search-panel {
  display: none;
  background-color: lighten($primary-color, 10%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  z-index: 9999;
  .search-bar {
    margin:auto;
    margin-top: 5rem;
    max-width: 420px;
  }
  .close-button {
    i {
      // color: #ccc;
      font-size: 3rem;
    }
  }
}

.backtotop {
  position: fixed;
  right: 2rem;
  opacity: 0;
  visibility: hidden;
  bottom: -2rem;
  transition: all 0.3s linear;
  a {
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}

body.scrolled {
  .backtotop {
    bottom: 2rem; 
    opacity: 1;
    visibility: visible;
  }
}

// ****************************************************************************
// *                                  footer                                  *
// ****************************************************************************

footer {
  margin-top: 4rem;
  background-color: #fff;
  border-top: solid 20px $accent-color;
  // border-top: solid 20px lighten($primary-color, 10%);
  a, h3 {
    color: #666;
  }
  .block-title {
    font-family: $display-font-family;
    font-size: 2.4rem;
    padding-top: 1rem;
  }
  .map-container {
    // text-align: center;
    p {
      // padding-top: 1rem; 
    }
  }
  .map {
    max-width: 180px;
  }
  .flinks-wrapper {
    @include tablet-down {
      order: 5;
      padding-top: 1rem;
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      margin: 0.5rem auto 1rem;
    }
    @include phone {
      border-top: none;
    }
    a {
      font-size: 1.2rem;
      padding: 0.5rem;
      @include tablet-down {
        font-size: 1rem;
      }
    }
  }
  .showroom {
    a {
      padding-top: 0.5rem;
      display: block;
    }
  }
  .footer-top {
    > div {
      @include phone {
        border-bottom: 1px solid #ccc;
        padding-bottom: 2rem;
        margin-bottom: 1rem;
      }
    }
  }
  .footer-bottom {
    padding-bottom: 6.4rem;
    p {
      margin-bottom: 0;
    }
  }
}



// ****************************************************************************
// *                                   home                                   *
// ****************************************************************************

.section-title {
  font-family: $display-font-family;
  @include phone {
    font-size: 2rem;
  }
}

.section-hero {
  padding-bottom: 2rem;
}

.section-carousel {
  .section-header {
    border-bottom: solid 1px #ccc;
  }
  .carousel-control {
    button {
      padding: 0.5rem 0.6rem;
      border: solid 1px #ccc;
      color: #fff;
      margin-left: 0.1rem;
      background-color: $primary-color;
      
    }
  }
}

.section-news {
  // background-color: #fafafa;
  .latest-news {
    li {
      padding: 1rem;
      // max-height: 360px;
      overflow: hidden;
    }
    a {
      color: #777;
    }
    .date {
      text-align: right;
      padding: 0;
      margin: 0;
      font-size: 1rem;
      color: #777;
    }

    .title {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.2;
      background-color: #fff;
      // background-color: transparentize(#fff, 0.4);
      padding: 0.5rem;
      text-align: center;
    }
    .item {
      position: relative;
      // top: 20px;
      // background-color: #fff;
      border: solid 1px #ddd;
      background-color: transparentize(#fff, 0.4);
      .excerpt {
        font-size: 1rem;
        font-weight: 400;
        color: #777;
      }
      .content {
        padding: 0.4rem 0.6rem;
      }
    }
    .date-box {
      width: 54px;
      text-align: center;
      position: absolute;
      top: -30px;
      left: 30px;
      .date {
        background-color: $primary-color;
        color: #fff;
        font-size: 2rem;
        line-height: 1.4;
      }
      .month {
        color: $primary-color;
        background-color: #FFF;
        font-size: 0.75rem;
      }
    }
  }
}


// ****************************************************************************
// *                               product list                               *
// ****************************************************************************



.product-list {
  li {
    .image {
      position: relative;
      z-index: 0;
    }
    .stock-status {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
    }
    &.out-of-stock {
      .stock-status {
        background: url('../img/soldout.png') no-repeat center center;
        width: 90px;
        height: 90px;
      }
    }
    padding: 1.4rem;
    @include phone {
      padding: 1rem;
    }
    .product-list-item {
      position: relative;
      background-color: #fff;
      border: solid 3px $accent-color;
      text-align: center;
      .stock-status {
        position: absolute;
        top: 0;
        right: 0; 
      }
      .content {
        color: #777;
      }
      .title {
        font-size: 1.2rem;
        font-weight: 700;
        text-transform: capitalize;
        padding: 0.5rem 0.5rem 0;
        line-height: 1.1;
      }
      .price {
        padding-bottom: 0.5rem;
      }
      .desc {
        color: #444;
        font-size: 0.8rem;
        font-style: italic;
      }
      .subtitle {
        color: #999;
        font-size: 0.9rem;
        border-top: solid 1px #ccc;
      }
    }
    .product-info {
      padding: 1rem;
    }
  }
  .product-atc {
    border: solid 3px $accent-color;
    border-top: 0;
    i {
      font-size: 2rem;
    }
    .input-group-label {
      background-color: $accent-color;
      border: none;
    }
    input {
      border: none;
    }
  }
  &.as-list {
    > li {
      padding: 0.5rem;
    }
    .product-list-item {
      padding-left: 105px;
      display: block;
      position: relative;
      text-align: left;
      height: 96px;
      .image {
        position: absolute;
        left: 0;
        top: 0;
        width: 90px;
        height: 90px;
        overflow: hidden;
      }
      .title {
        padding: 0;
      }
    }
  }
}


.page-product-list {
  .page-title {
  }
  .pagination-next a::after, .pagination-next.disabled::after, .pagination-previous a::before, .pagination-previous.disabled::before {
    display: none;
  }

  .pagination {
    padding-bottom: 2rem;
    padding-top: 2rem;
    i {
      vertical-align: middle;
      padding: 0 0.5rem;
    }
    a {
      // font-size: 1rem;
    }
  }
  .pagination a:hover, .pagination button:hover {
    background-color: $accent-color;
  }
  .section-product-kit-list {
    padding-bottom: 5rem;
    .section-header {
      // border-bottom: solid 1px #999;
    }
  }
  .section-product-list {
    .section-title {
      padding-left: 1rem;
      border-bottom: solid 1px #ccc;
    }
  }
}

.product-images {
  // max-width: 420px;
  margin: auto;
}


.product-header {
  text-align: center;
  padding-bottom: 2rem;
  @include phone {
    padding-bottom: 1rem;
  }
}

.page-product-details {
  .page-title {
    padding-bottom: 0.5rem;
    font-size: 3rem;
    @include phone {
      font-size: 2rem;
      padding-bottom: 0;
    }
  }
  .category-list {
    a .label {
      font-weight: 700;
      font-size: 1rem;
    }
    > li {
      margin-bottom: 0.25rem;
    }
  }
  .price {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .section-product-info {
    padding-top: 2rem;
    .attribute {
      font-size: 1.2rem;
      padding: 2rem;
      @include phone {
        padding: 1rem 2rem;
      }
      background-color: transparentize($primary-color, 0.6);
      border: solid 2px #ccc;
      .attr-label {
        font-weight: 700;
      }
      .button {
        margin-top: 1rem;
      }
    }
    .how-to-order {}
  }
  .section-product-description {
    padding: 2rem;
  }
}

.page-contact {
  .fsocials-wrapper {
    padding: 1rem;
    background-color: transparentize(#fff, 0.4);
    max-width: 320px;
  }
  
}


.fsocials {
  a {
    display: block;
    padding: 0.5rem;
    font-size: 1.2rem;
  }
  i {
    font-size: 1.8rem;
    width: 30px;
    text-align: center;
    vertical-align: middle;
  }
  img {
    width: 30px;
  }
}

.floating-help {
  position: fixed;
  bottom: 2rem;
  right: 8rem;
  width: auto;
  > div {
    background-color: $accent-color;
    padding: 1rem 1.5rem;
    border-radius: 4px;
    padding-right: 3rem;
    a {
      font-weight: 700;
    }
  }
  button {
    // margin-left: 1rem;
  }
}

.page-login, .page-registration {
  .page-title {
    padding-bottom: 0;
  }
  .login-icon {
    font-size: 10rem;
    color: $primary-color;
  }
  form {
    max-width: 480px;
    margin: auto;
  }
  .login-but {
    text-align: center;
    padding-top: 1rem;
    a {
      color: darken($primary-color, 40%);
      margin-bottom: 1rem;
      display: block;
    }
  }
}

.form-actions {
  padding-top: 2rem;
  padding-bottom: 2rem;
}


.section-welcome {
  height: 480px;
  background: url('../img/hero1.jpg') center center no-repeat;
  background-size: cover;
  padding: 3rem 50% 3rem 3rem;
  @include phone {
    background-position: 70%;
  }
  .welcome {
    padding: 3rem;
    background-color: transparentize(#fff, 0.3);
    // width: 50%;
    font-size: 2rem;
  }
}