/// Convert any string to a "safe" string that can be used anywhere in CSS (as class or keyframe name for example).
/// Unsupported characters are replaced by the given `$delimiter` ("-").
/// Several unsupported characters following each others are replaced by a single delimiter.
///
/// @param {*} $str - String to convert. If not a string, it will be converted to with `quote()`.
/// @param {String} $delimiter ['-'] - Character to use instead of unsupported characters.
/// @return {String} - Safe string usable everywhere in CSS.
@function -mui-string-safe(
  $str,
  $delimiter: '-'
) {
  $str: quote($str);
  $length: str_length($str);
  $safe-chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

  $ret: '';
  $delimited: false;

  @for $i from 1 through $length {
    $c: str_slice($str, $i, $i);

    @if (str_index($safe-chars, $c) != null) {
      $ret: '#{$ret}#{$c}';
      $delimited: false;
    }
    @else if (($delimited == false)
      and (str_length($ret) > 0)
      and ($i < $length))
    {
      $ret: '#{$ret}#{$delimiter}';
      $delimited: true;
    }
  }

  @return $ret;
}
