/// Creates a sliding animation.
/// @param {Keyword} $state [in] - Whether to move to (`in`) or from (`out`) the element's default position.
/// @param {Keyword} $direction [null] - Direction to move. Can be `up`, `right`, `down`, or `left`. By default `left` and `right` for `in` and `out` states respectively.
/// @param {Number} $amount [100%] - Distance to move. Can be any CSS length unit.
/// @return {Map} A keyframes map that can be used with the `generate-keyframes()` mixin.
@function slide(
  $state: in,
  $direction: null,
  $amount: 100%
) {
  $from: $amount;
  $to: 0;
  $func: 'translateY';
  $direction: if($direction != null, $direction, if($state == in, left, right));

  @if $direction == left or $direction == right {
    $func: 'translateX';
  }

  @if $state == out {
    $from: 0;
    $to: $amount;
  }

  @if $direction == down or $direction == right {
    @if $state == in {
      $from: -$from;
    }
  } @else {
    @if $state == out {
      $to: -$to;
    }
  }

  $keyframes: (
    name: -mui-string-safe('slide-#{$state}-#{$direction}-#{$amount}'),
    0: (transform: '#{$func}(#{$from})'),
    100: (transform: '#{$func}(#{$to})'),
  );

  @return $keyframes;
}
